const filterBounds =  {
  whiteDwarf: {
    label: "white dwarfs",
    points: [
      [-0.75, 1.25],
      [-0.75, 1.25],
      [-0.1, 1.25],
      [1, 16],
      [-0.75, 16],
      [-0.75, 16],
    ],
    radius: 9
  },
  subGiants: {
    label: "sub giants",
    points: [
      [0.75, 1.25],
      [1.6, 2.5],
      [4, 12],
      [1, 4],
      [0.75, 1.25],
    ],
    radius:  13
  },
  blueGiants: {
    label: "blue giants",
    points: [
      [-0.75, 1.25],
      [0.75, 1.25],
      [0.75, -6],
      [-0.75, -6],
      [-0.75, 1.25]
    ],
    radius: 16
  },
  redGiants: {
    label: "red giants",
    points: [
      [0.75, 1.25],
      [1.6, 2.5],
      [7, -2.5],
      [7, -6],
      [0.75, -6],
      [0.75, 1.25],
    ],
    radius: 19
  },
  mainSequence: {
    label: "main sequence",
    points: [
      [0.75, 1.25],
      [-0.1, 1.25],
      [1.0, 16],
      [3.2, 16],
      [4, 16],
      [4, 12],
      [1, 4],
      [0.75, 1.25]
    ],
    radius: 9
  },
  curated: {
    label: "curated",
    points: [
      [0, 0.0],
      [0.1, 0.1],
      [0, 0.1]
    ],
    radius:  13
  },
}

export default filterBounds