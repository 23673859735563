import React from 'react';
import ShareBtn from '@material-ui/icons/Send';

function Share() {

  function copy() {
    const el = document.createElement('input');
    el.value = window.location.href;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    window.alert("star url has been saved to clipboard")
  }

  return (
    <ShareBtn 
      style={{
        cursor: "pointer"
      }}
      onClick={copy}
    />
  );
}

export default Share;