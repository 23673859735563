import {React, useState} from 'react';
import {makeStyles} from "@material-ui/core"
import Radio from "./Radio"
import RemoveOutlinedIcon from '@material-ui/icons/RemoveOutlined';
import StarIcon from '@material-ui/icons/Star'
import ReactTooltip from './MobileReactTooltip';

const useStyles = makeStyles(() => ({
  container: {
    position: "absolute",
    bottom: "0px",
    left: "0px",
    width: "200px",
    height: "250px",
    backgroundColor: "black",
    borderWidth: 1,
    borderColor: "white",
    borderStyle: "solid",
    zIndex: 26
  },
  wrapper: {
    position: "relative",
    width: "100%",
    height: "100%"
  },
  content: {
    position: "absolute",
    top: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center"
  },
  borderDiv: {
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    paddingTop: "10px"
  },
  minBtn: {
    position: "absolute",
    top: "10px",
    right: "28px",
    "&:hover": {
      cursor: "pointer"
    },
  },
  header: {
    fontFamily: `"Roboto", "Helvetica", "Arial", "sans-serif"`,
    fontSize: '14px',
    color: "white", 
    marginLeft: "5%",
    marginTop: "4px",
    marginBottom: "12px"
  },
  starBtn: {
    // position: "absolute",
    // top: "20px",
    // right: "90px",
    "&:hover": {
      cursor: "pointer"
    },
    zIndex: 20  
  },
}));

const StarFilters = ({filterData, initFilterState, filterBounds}) => {
  const classes = useStyles()
  const [isShowingStarFilters, setIsShowingStarFilters] = useState(false)

  const handleStarFilters = () => setIsShowingStarFilters(prevState =>  !prevState)

    if (!isShowingStarFilters) {
      return (
        <>
          <div data-for="filter" data-tip="star filters">
            <StarIcon className={classes.starBtn} onClick={handleStarFilters}/> 
          </div>
          <ReactTooltip id="filter"/>
        </>
      )
    } else {
      return (
        <>
          <div className={classes.container}>
            <div className={classes.wrapper}>
              <div className={classes.content}>
                <div className={classes.borderDiv}>
                  <h3 className={classes.header}>
                    Star Filters
                  </h3>
                  <hr/>
                  <Radio
                    // filterState={props.filterState}
                    // setFilterState={props.setFilterState}
                    filterBounds={filterBounds}
                    initFilterState={initFilterState}
                    filterData={filterData}
                  />
                </div>
              </div>
              <RemoveOutlinedIcon 
                className={classes.minBtn}
                onClick={handleStarFilters}
              />
            </div>
          </div>
        </>
      )
    }
}

export default StarFilters;