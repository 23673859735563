import {IconButton, makeStyles } from "@material-ui/core"
import RemoveOutlinedIcon from '@material-ui/icons/RemoveOutlined';

const MinBtn = (props) => {

    const useStyles = makeStyles((theme) => ({
        infoDiv: {
            position: "absolute", /* Safari */
            top: "5px",
            right: "10px"
        },
        btn: {
            color: "white"
        }
    }));
    const classes = useStyles();
    return (
        <div className={classes.infoDiv}>
            <IconButton onClick={props.handleInfo} fontSize="small" className={classes.btn}>
                <RemoveOutlinedIcon/>
            </IconButton>
        </div>
    )
}

export default MinBtn