import React, { useEffect, useState} from "react"
import {makeStyles} from "@material-ui/core"
import MenuIcon from '@material-ui/icons/Menu'
import {Link} from "react-router-dom"
import { Minimize } from "@material-ui/icons"

const Menu = (props) => {
    const [menuIndex, setMenuIndex] = useState(0)
    const [width, setWidth] = useState(window.innerWidth)
    const [showMenu, setShowMenu] = useState(false)
  
    const handleMenu = () => {
        setShowMenu(prevState => {return !prevState})
    }

    const menuSelections= ["Star Sounder", "About", "Contact"]
    const routeMap = {
        "Star Sounder": "/",
        "About": "/about",
        "Contact": "/contact",
    }
    const isMobile = width < 700

    let btnContainer = {
        position: "fixed",
        flexDirection: "row",
        paddingTop: "15px",
        paddingLeft: "130px",
        paddingBottom: "20px",
        width: "100%",
    }
    
    if (isMobile) {
        btnContainer = {
            position: "fixed",
            flexDirection: "column",
            height: "100%",
            width: "100%",
            textAlign: "center",
            paddingTop: "20px",
            zIndex: "30",
            backgroundColor: "black"
        }
    }

    useEffect(() => {
        window.addEventListener(
            'resize', 
            () => {
                setWidth(window.innerWidth)
            }
        );
        return () => {
            window.removeEventListener(
                'resize', 
                () => {setWidth(window.innerWidth)}
            );
        }
    }, [])

    // useEffect(() => {
    //     let adjustMenu = !isMobile ? setShowMenu(false) : -1
    // }, [isMobile])

    const useStyles = makeStyles(() => ({
        minBtn: {
            position: "fixed",
            top: "20px",
            left: "25px",
            "&:hover": {
                cursor: "pointer"
            },
            zIndex: "40"
        },
        link: {
            textDecoration: "none",
        },
        menuBtn: {
            border: "white",
            color: "white",
            "&:hover": {
                textDecoration: "underline",
                cursor: "pointer"
            },
            marginRight: "10px",
            zIndex: 6,
            fontFamily: `"Roboto", "Helvetica", "Arial", "sans-serif"`,
            fontSize: '16px',
        },
        logo: {
            borderColor: "white",
            color: "white",
            borderStyle: "solid",
            textDecoration: "none",
            paddingLeft: "10px",
            paddingRight: "10px",
            "&:hover": {
                cursor: "pointer",
                color: "black",
                backgroundColor: "white",
            },
            marginRight: "10px",
            borderWidth: "thin",
            zIndex: 6,
            fontFamily: `"Roboto", "Helvetica", "Arial", "sans-serif"`,
            fontSize: '16px',
        },
        btn: {
            position: "absolute",
            top: "20px",
            left: "30px",
            "&:hover": {
                cursor: "pointer"
            },
            zIndex: 6
        },
        btnContainer: {
            position: "absolute",
            opacity: 1.0,
            display: "flex",
            alignItems: "center",
            ...btnContainer
        }
    }));
    const classes = useStyles();

    const Buttons = () => {
        return (
            menuSelections.map((menuData, i) => {
                console.log(i)
                const isLogo = menuData === menuSelections[0]
                const selected = !isLogo ? 
                    {textDecoration: "underline"} : 
                    {color: "black", backgroundColor: "white"}
                return (
                    <Link 
                        onClick={() => {
                            handleMenu()
                            setMenuIndex(i)
                        }}
                        key={menuData} 
                        className={classes.link}
                        to={routeMap[menuData]}
                    >
                        <h1 
                            className={isLogo ? classes.logo : classes.menuBtn}
                            // variant={isLogo ? "subtitle2" : "body2"}
                            style={i === menuIndex ? selected : {}}
                        >
                            {menuData} 
                        </h1>
                    </Link>
                )
            })
        )
    } 

    const DesktopMenu = () => {
        return (
            <div className={classes.btnContainer}>
                <Buttons/>
            </div>
        )
    }

    const MobileMenu = () => {
        return (
            !showMenu ? <MenuIcon className={classes.minBtn} onClick={handleMenu}/> 
            :   <>
                <div className={classes.btnContainer}> 
                    <Minimize className={classes.minBtn} onClick={handleMenu}/>
                    <DesktopMenu/>
                </div>
            </>
        )
    }

    return (
        isMobile ? <MobileMenu/> : <DesktopMenu/>
    )
}

export default Menu