import React, { useRef } from "react"
import {useEffect, useState} from "react"
import * as d3 from 'd3'
import { axisBottom, axisLeft, zoomTransform } from "d3";
import {makeStyles} from "@material-ui/core";
import QuadTree from '@timohausmann/quadtree-js';
import {useSearchParams} from "react-router-dom"
import filterBounds from "../data/filterBounds"
import useMobile from "../hooks/useMobile";

const filterLabelMap = {
    'blue giants': 'blueGiants',
    'curated': 'curated',
    'main sequence': 'mainSequence',
    'red giants': 'redGiants',
    'sub giants': 'subGiants',
    'white dwarfs': 'whiteDwarf'
}

// define domains and ranges for various  scales
const xAxisDomain = [-1.0, 5.5]
const yAxisDomain = [-8, 16]
const xColorScaleDomain = [-0.5, 0.625, 1.75, 2.875, 4.0]
const xColorScaleRange = ["blue", "white", "yellow", "orange", "red"]

const getLinearScale = (domain, range) => {
    return d3.scaleLinear()
        .domain(domain)
        .range(range)
}

const fetchSearchParam = (param, defaultParam, searchParams) => {
    if (searchParams.has(param)) {
      return (searchParams.get(param))
    }
    return defaultParam
}

const Stars = (props) => {

    const {starData, starObjects, setParamsString, paramsString, initFilterState, filterData} = props

    const getSVGWidth = (widthReduction) => window.innerWidth - widthReduction
    const getSVGHeight = () => window.innerHeight - (170)
    const [platformMobile] = useMobile()

    const isMobile = (getSVGWidth(0)) < 700
    
    let marginLeft = 100
    if (isMobile) {
        marginLeft = 0
    }

    const widthReduction = marginLeft * 2

    let innerMargin = 30
    const marginX2 = innerMargin * 2
    const marginTop = 40
    const radius = 5
    const diameter = radius * 2
    const proximityThreshold = diameter


    const [thisStarData, setThisStarData] = useState([...starData])
    const [svgWidth, setSVGWidth] = useState(getSVGWidth(widthReduction))
    const [svgHeight, setSVGHeight] = useState(getSVGHeight(widthReduction))
    const [currentZoomState, setCurrentZoomState] = useState(NaN)
    const [searchParams, setSearchParams] = useSearchParams()
    const [filterState, setFilterState] = useState(() => {
        let thisInitState = {...initFilterState}
        filterData.forEach(key => {
            if (searchParams.has(key)) {
            thisInitState = {
                ...thisInitState,
                [key]: JSON.parse(searchParams.get(key))
            }
            }
        })
        return thisInitState
    })

    const currentZoomStateRef = useRef()
    const svgRef = useRef()
    const canvasRef = useRef()
    const ctx = useRef()
    const oCanvasRef = useRef()
    const octx = useRef()
    const boundaryCanvas = useRef()
    const bctx = useRef()
    const textRef = useRef()
    const textctx = useRef()
    const quadTree = useRef(NaN)
    const paramsStringRef = useRef(paramsString)

    const canvasWidth = svgWidth
    const canvasHeight = svgHeight

    const initCanvasXScale = getLinearScale(xAxisDomain, [0, canvasWidth])
    const initCanvasYScale = getLinearScale(yAxisDomain, [0, canvasHeight])
    const [starID, setStarID] = useState(() => fetchSearchParam("starID", starData[0].kepid, searchParams)) 
    const initStar = starObjects[starID]
    const [selectedStar, setSelectedStar] = useState({
        x: initCanvasXScale(initStar.bp_rp),
        y: initCanvasYScale(initStar.abs_gmag),
        width: diameter,
        height: diameter,
        id: initStar.kepid,
        bp_rp: initStar.bp_rp,
        abs_gmag: initStar.abs_gmag
    })

    useEffect(() => {
        currentZoomStateRef.current = currentZoomState
    }, [currentZoomState])

    useEffect(() => {
        let newStarData = []
        filterData.forEach(key => {
            if (filterState[key]) {
                newStarData = [...newStarData, ...filterBounds[filterLabelMap[key]]['stars']]
            }
        })   
        setThisStarData(newStarData)
    }, [filterState, filterData])

    useEffect(() => {
        const star = starObjects[starID]
        const canvasXScale = getLinearScale(xAxisDomain, [0, svgWidth - marginX2])
        const canvasYScale = getLinearScale(yAxisDomain, [0, svgHeight - marginX2])
        setSelectedStar({
            x: canvasXScale(star.bp_rp),
            y: canvasYScale(star.abs_gmag),
            width: diameter,
            height: diameter,
            id: star.kepid,
            bp_rp: star.bp_rp,
            abs_gmag: star.abs_gmag
        })
    }, [starID, setSelectedStar, svgWidth, svgHeight, diameter, marginX2, starObjects])

    const canvasStyle = {
        position: "fixed", 
        left: marginLeft, 
        top: marginTop
    }

    const createLabel = (name, x, y, fontSize, rotate, svg) => {
        if (rotate) {
            svg.selectAll(name)
            .attr("transform", "rotate(90)")        
        }
        svg.selectAll(name)
        .attr("x", x + "px")
        .attr("y", y + "px")
        .style("fill", "white")
        .style("text-anchor", "middle")
        .style("font-size", fontSize)
    }

    const clearCanvas = (ctx) => ctx.clearRect(0, 0, canvasWidth, canvasHeight)

    useEffect(() => {
        return () => {
            setParamsString(paramsStringRef.current)
        }
    }, [setParamsString])

    // useEffect(() => {
    //     console.log(resetStars)
    //     if (resetStars) {
    //         setSearchParams({starID: starID})
    //         paramsStringRef.current = ""
    //     }
    // }, [resetStars, setResetStars])

    useEffect(() => {
        const urlSeachParams = new URLSearchParams("?" + paramsString)

        let theseSearchParams = {...initFilterState}

        filterData.forEach(key => {
            if (urlSeachParams.has(key)) {
                theseSearchParams = {
                    ...theseSearchParams,
                    [key]: JSON.parse(urlSeachParams.get(key))
                }
            }
        })

        urlSeachParams.forEach((value, key, urlSeachParams) => {
            theseSearchParams = {...theseSearchParams, [key]: JSON.parse(urlSeachParams.get(key))}
        })

        let zoomState = d3.zoomIdentity
        const canvasXScale = getLinearScale(xAxisDomain, [innerMargin, svgWidth - marginX2])
        const canvasYScale = getLinearScale(yAxisDomain, [innerMargin, svgHeight - marginX2])
        if (theseSearchParams['starID']) {
            const star = starObjects[theseSearchParams['starID']]
            setSelectedStar({
                x: canvasXScale(star.bp_rp),
                y: canvasYScale(star.abs_gmag),
                width: diameter,
                height: diameter,
                id: star.kepid,
                bp_rp: star.bp_rp,
                abs_gmag: star.abs_gmag
            })
        } 
        setCurrentZoomState(zoomState)
        setSearchParams(theseSearchParams)
    }, [paramsString, starObjects, setSearchParams, diameter, marginX2, initFilterState, filterData, svgWidth, svgHeight, innerMargin])

    // useEffect(() => {
    //     const canvas = d3.select(textRef.current)
    //     const zoomBehavior = d3.zoom()
    //     // const localStorage = window.localStorage
    //     // const prevZoomState = JSON.parse(localStorage.getItem('zoomState'))
    //     let zoomState = d3.zoomIdentity
    //     if (searchParams.has('x')) {
    //         zoomState = zoomState.translate(
    //             searchParams.get('x'), 
    //             searchParams.get('y')
    //         ).scale(searchParams.get('k'))
    //         canvas.transition().call(zoomBehavior.transform, zoomState)
    //     } 
    //     setCurrentZoomState(zoomState)
    // }, [setCurrentZoomState])

    useEffect(() => {
        let newState = {...initFilterState}
        filterData.forEach(key => {
            if (searchParams.has(key)) {
                newState = {
                    ...newState,
                    [key]: JSON.parse(searchParams.get(key))
                }
            }
        })
        setFilterState(newState)
        let newSearchParams = {...newState}
        if (searchParams.has("starID")) {
            setStarID(searchParams.get("starID"))
            newSearchParams = {...newSearchParams, starID: searchParams.get("starID")}
        }
        if (searchParams.has("reset")) {
            if (searchParams.get("reset") === "true") {
                if (currentZoomStateRef.current) {
                    const canvas = d3.select(textRef.current)
                    const zoomBehavior = d3.zoom()
                    .scaleExtent([1, 50])
                    .on("zoom", () => {
                        const zoomState = zoomTransform(canvas.node())
                        setCurrentZoomState(zoomState)
                    })
                    // canvas.call(zoomBehavior)
                    let zoomState = d3.zoomIdentity
                    canvas.transition().call(zoomBehavior.transform, zoomState) 
                    // setCurrentZoomState(zoomState)
                }
            }
        }
        setSearchParams({
            ...newSearchParams,
        })
        paramsStringRef.current = searchParams.toString()
    }, [searchParams, filterData, initFilterState, setSearchParams])

    useEffect(() => {
        ctx.current = canvasRef.current.getContext("2d")
        octx.current = oCanvasRef.current.getContext("2d")
        textctx.current = textRef.current.getContext("2d")
        bctx.current = boundaryCanvas.current.getContext("2d")

        const clipCanvas = (ctx) => {
            ctx.rect(innerMargin, innerMargin, svgWidth - marginX2, svgHeight - marginX2)
            ctx.clip()
        }

        clipCanvas(ctx.current)
        clipCanvas(octx.current)
        clipCanvas(textctx.current)
        clipCanvas(bctx.current)
        setSVGHeight(getSVGHeight())
        setSVGWidth(getSVGWidth(widthReduction))
        const canvas = d3.select(textRef.current)
        const zoomBehavior = d3.zoom()
        .scaleExtent([1, 50])
        .on("zoom", () => {
            const zoomState = zoomTransform(canvas.node())
            setCurrentZoomState(zoomState)
        })
        canvas.call(zoomBehavior)

        quadTree.current = new QuadTree({
            x: 0,  
            y: 0,
            width: (svgWidth - (innerMargin * 2)),
            height: (svgHeight - (innerMargin * 2))
        }, 15, 6)

        if (currentZoomStateRef.current) {
            let zoomState = d3.zoomIdentity
            zoomState = zoomState.translate(
                currentZoomStateRef.current.x, 
                currentZoomStateRef.current.y
            ).scale(currentZoomStateRef.current.k)
            canvas.transition().call(zoomBehavior.transform, zoomState) 
        }

        const handleResize = () => {
            setSVGHeight(getSVGHeight())
            setSVGWidth(getSVGWidth(widthReduction))
        }
        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [setSVGHeight, setSVGWidth, svgWidth, svgHeight, innerMargin, widthReduction, marginX2])

    useEffect(() => {
        const rescale = (orgXScale, orgYScale, zoomState) => {
            const newZoomXScale = zoomState.rescaleX(orgXScale)
            const newZoomYScale = zoomState.rescaleY(orgYScale)
            orgXScale.domain(newZoomXScale.domain())
            orgYScale.domain(newZoomYScale.domain())
        }

        const clearCanvas = (thisCTX) => thisCTX.clearRect(0, 0, canvasWidth, canvasHeight)

        const drawStar = (ctx, star, fill) => {
            ctx.beginPath();
            ctx.arc(
                star.x,
                star.y, 
                radius, 
                0, 
                2 * Math.PI, 
                false
            );
            ctx.closePath();
            ctx.fillStyle = fill;
            ctx.fill();
        }
    
        const drawStars = (canvasXScale, canvasYScale, xColorScale) => {
            const thisQuad = quadTree.current
            thisQuad.clear()
            clearCanvas(ctx.current)
            clearCanvas(octx.current)
            clearCanvas(textctx.current)
            if (thisStarData) {
                thisStarData.forEach(d => {
                    const thisStar = {
                        x: canvasXScale(d.bp_rp),
                        y: canvasYScale(d.abs_gmag),
                        width: diameter,
                        height: diameter,
                        id: d.kepid,
                        bp_rp: d.bp_rp,
                        abs_gmag: d.abs_gmag
                    }
                    thisQuad.insert(thisStar)
                    const fill = xColorScale(thisStar.bp_rp)
                    if (
                        (thisStar.x <= canvasWidth + radius) 
                        && (thisStar.y <= canvasHeight + radius)
                        && (thisStar.x >= -1 * radius)
                        && (thisStar.y >= -1 * diameter)
                    ) {
                        if (selectedStar && (thisStar.id === selectedStar.id)) {
                            drawStar(ctx.current, thisStar, fill)
                        } else {
                            drawStar(octx.current, thisStar, fill)
                        }
                    }
                })
            }
        }

        const drawBoundaries = (canvasXScale, canvasYScale) => {
            // clearCanvas(bctx.current)
            // bctx.current.rect(innerMargin, innerMargin, svgWidth - marginX2, svgHeight - marginX2)
            // bctx.current.stroke()
            // bctx.current.lineWidth = 1
            // bctx.current.strokeStyle = "white";
            // bctx.current.fillStyle = "white"
            // Object.keys(filterBounds).forEach((key) => {
            //     bctx.current.beginPath()
            //     filterBounds[key].points.forEach((point, i) => {
            //         const x = canvasXScale(point[0])
            //         const y = canvasYScale(point[1])
            //         i < 1 ? bctx.current.moveTo(x, y) : bctx.current.lineTo(x, y)
            //         const text = "[" + point[0] + ", " + point[1] + "]"
            //         const textWidth = bctx.current.measureText(text).width
                    
            //         bctx.current.fillText(text, x - textWidth / 2,  (y - 10))

            //     })
            //     const label = filterBounds[key].label
            //     const labelWidth = bctx.current.measureText(label).width
            //     let centroid = filterBounds[key].centroid
            //     const centroidX = canvasXScale(centroid[0])
            //     const centroidY = canvasYScale(centroid[1])
            //     bctx.current.fillText(label, centroidX - labelWidth / 2, centroidY)
            //     bctx.current.closePath()
            //     bctx.current.stroke()
            // })
    
        }

        const drawGraph = () => {
            const svg = d3.select(svgRef.current)
            const xAxisRange = [innerMargin, svgWidth - innerMargin]
            const yAxisRange = [innerMargin, svgHeight - innerMargin]
            let xScale = getLinearScale(xAxisDomain, xAxisRange)
            let yScale = getLinearScale(yAxisDomain, yAxisRange)
            let canvasXScale = getLinearScale(xAxisDomain, [0, svgWidth - marginX2])
            let canvasYScale = getLinearScale(yAxisDomain, [0, svgHeight - marginX2])

            if (currentZoomState && currentZoomState.rescaleX) {
                rescale(xScale, yScale, currentZoomState)
                rescale(canvasXScale, canvasYScale, currentZoomState)
            }
    
            const xAxis = axisBottom(xScale)
    
            svg.select(".x-axis")
            .call(xAxis)
            .style("transform", "translateY(" + (innerMargin) + "px")
            .attr("clip-path", "url(#cut-text)")
    
            const yAxis = axisLeft(yScale)
    
            svg.select(".y-axis")
            .call(yAxis)
            .style("transform", "translateX(" + (svgWidth - innerMargin) + "px)")
            .attr("clip-path", "url(#cut-text)")
    
            const labelFontSize = "16px"
            const boundFontSize = "12px"
    
            const xLabelHeight = ((innerMargin / 2) + 5)
            const xBoundHeight = xLabelHeight + 3
            const shrinkFactor = 2

            createLabel(
                ".x-axis-label",
                (svgWidth / 2),
                xLabelHeight,
                labelFontSize,
                false,
                svg
            )
            createLabel(
                ".x-axis-max",
                (innerMargin * shrinkFactor),
                xBoundHeight,
                boundFontSize,
                false,
                svg
            )
            createLabel(
                ".x-axis-min",
                (svgWidth - (innerMargin * shrinkFactor)),
                xBoundHeight,
                boundFontSize,
                false,
                svg
            )
    
            const yLabelHeight = -(svgWidth - (innerMargin / 1.5))
            const yBoundHeight = yLabelHeight + 5
    
            createLabel(
                ".y-axis-label",
                svgHeight / 2,
                yLabelHeight,
                labelFontSize,
                true,
                svg
            )
    
            createLabel(
                ".y-axis-max",
                svgHeight - (innerMargin * shrinkFactor),
                yBoundHeight,
                boundFontSize,
                true,
                svg
            )
    
            createLabel(
                ".y-axis-min",
                (innerMargin * shrinkFactor),
                yBoundHeight,
                boundFontSize,
                true,
                svg
            )

            const xColorScale = d3.scaleLinear(xColorScaleDomain, xColorScaleRange)
            
            drawStars(xScale, yScale, xColorScale)
            drawBoundaries(xScale, yScale)
        }
        if (!currentZoomState || (currentZoomState && currentZoomState.rescaleX)) {
            drawGraph()
        }
    }, [thisStarData, currentZoomState, svgWidth, svgHeight, selectedStar, canvasHeight, canvasWidth, diameter, marginX2, innerMargin])

    const drawToolTip = (star) => {
        const fontsize = 14
        const fontface = 'verdana'
        const text = " " + star.id + " "
        const ctx = textctx.current
        // const textWidth = ctx.measureText(text).width
        const rectWidth = 90
        const rectHeight = 20
        const rectX = star.x - (rectWidth / 2)
        const rectY = star.y - 20 - rectHeight
        const textX = star.x
        const textY = rectY + (rectHeight / 2)
        
        ctx.font = fontsize + 'px ' + fontface;
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';

        // text box
        ctx.fillStyle = "black";
        ctx.fillRect(rectX, rectY, rectWidth, rectHeight);
        ctx.fillStyle = "white";
        ctx.beginPath();
        ctx.lineWidth = "1";
        ctx.strokeStyle = "white";
        ctx.rect(rectX, rectY, rectWidth, rectHeight);
        ctx.closePath()
        ctx.stroke();
        ctx.fillText(text, textX, textY);
    }

    useEffect(() => {
        if (selectedStar) {
            const searchObj = Object.fromEntries(new URL(window.location.href).searchParams.entries())
            setSearchParams({
                ...searchObj,
                'starID': selectedStar.id,
            })
        }
    }, [selectedStar, setSearchParams])

    useEffect(() => {
        if (filterState) {
            const searchObj = Object.fromEntries(new URL(window.location.href).searchParams.entries())
            setSearchParams({
                ...searchObj,
                ...filterState
            })
        }
    }, [filterState, setSearchParams])

    const getClosestStar = (e) => {
        const thisQuad = quadTree.current
        const xCanvasMouse = e.clientX - (marginLeft)
        const yCanvasMouse = e.clientY - (marginTop)
        const stars = thisQuad.retrieve({
            x: xCanvasMouse, 
            y: yCanvasMouse, 
            width: diameter, 
            height: diameter
        })    
        if (stars.length > 0) {
            let minStar = stars[0];
            let minDistance = 100000
            stars.forEach(star => {
                const distance = Math.sqrt(
                    Math.pow((star.x - xCanvasMouse), 2)
                    + Math.pow((star.y - yCanvasMouse), 2)
                )
                if (distance < minDistance) {
                    minDistance = distance
                    minStar = star
                }
            })
            return [minStar, minDistance]
        } else {
            return [NaN, NaN]
        }  
    }

    const handleMouse = (isClick, e) => {
        if (quadTree.current) {
            const [minStar, minDistance] = getClosestStar(e)
            clearCanvas(textctx.current)
            if (minStar) {
                if (minDistance < proximityThreshold) {
                    if (!platformMobile) {
                        drawToolTip(minStar)
                    }
                    if (isClick) {
                        setStarID(minStar.id)
                    }
                }
            }   
        }
    }

    const handleMouseMove = (e) => handleMouse(false, e)
    const handleMouseClick = (e) => handleMouse(true, e)
    const handleMouseLeave = () => handleMouse(false, {clientX: -100, clientY: -100})

    const useStyles = makeStyles((theme) => ({
        canvas: {
            "&:hover": {
                cursor: "pointer"
            }, 
        }
    }));

    const classes = useStyles();

    return (
        <>
            <canvas 
                ref={canvasRef}
                style={{
                    ...canvasStyle,
                    zIndex: "2"
                }}
                className={classes.canvas}
                width={canvasWidth}
                height={canvasHeight}
            >
            </canvas>
            <canvas 
                ref={textRef}
                onClick={handleMouseClick}
                onMouseMove={handleMouseMove}
                onMouseLeave={handleMouseLeave}
                style={{
                    ...canvasStyle,
                    zIndex: "5"
                }}
                className={classes.canvas}
                width={canvasWidth}
                height={canvasHeight}
            >
            </canvas>
            <canvas 
                ref={oCanvasRef}
                style={{
                    ...canvasStyle,
                    zIndex: "3",
                    opacity: 0.5,
                }}
                className={classes.canvas}
                width={canvasWidth}
                height={canvasHeight}
            >
            </canvas>
            <canvas 
                ref={boundaryCanvas}
                style={{
                    ...canvasStyle,
                    zIndex: "3",
                }}
                className={classes.canvas}
                width={canvasWidth}
                height={canvasHeight}
            >
            </canvas>
            <svg 
                style={{
                    ...canvasStyle,
                    zIndex: "1"
                }} 
                className={classes.canvas}
                width={svgWidth} 
                height={svgHeight} 
                ref={svgRef}
            >
                <rect 
                    x={innerMargin} 
                    y={innerMargin} 
                    width={svgWidth - marginX2} 
                    height={svgHeight - marginX2}
                    style={{stroke: "rgb(255,255,255)", strokeWidth: 2}}
                />
                <text className="x-axis-label">color</text>
                <text className="star-id">{"KIC" + starID}</text>
                <text className="x-axis-min">red</text>
                <text className="x-axis-max">blue</text>
                <text className="y-axis-label">brightness</text>
                <text className="y-axis-min">bright</text>
                <text className="y-axis-max">faint</text>
                <clipPath id="cut-axis">
                    <rect 
                        x={innerMargin - 1} 
                        y={innerMargin - 1} 
                        width={svgWidth - marginX2} 
                        height={svgHeight - marginX2}
                    />
                </clipPath> 
                {/* <g className="x-axis" />
                <g className="y-axis" />      */}
                <g clipPath={"url(#cut-axis)"}>
                    <g className="x-axis" />
                    <g className="y-axis" />     
                </g>
            </svg>
        </>
    )
}

export default Stars