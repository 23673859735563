import {useState, useEffect} from 'react'
import {makeStyles} from "@material-ui/core"
import {aboutData, imageData} from '../data/aboutData'
import useWindowSize from '../hooks/useWindowSize'

import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons'

// const aboutData = about.map(thisText => thisText.split('\n'))
// let aboutEmpty = aboutData.map((data, i) => i);
// aboutEmpty.pop()
const imgSrcPath = "/imgs/"

function isObject(objValue) {
  return objValue && typeof objValue === 'object' && objValue.constructor === Object;
}

const AboutText = ({data}) => {
  const [isShowing, setIsShowing] = useState(true)
  const [windowWidth] = useWindowSize()

  const handleClick = () => setIsShowing(prevState => !prevState)
  
  const textStyle = {fontFamily: `"Roboto", "Helvetica", "Arial", "sans-serif"`,
  color: "white"}

  const ImgWrapper = ({imgData}) => {
    const {img, link, figure, credit, float, width} = imgData
    const imgPath = imgSrcPath + img
    const imgStyle = {
      width: "100%",
      margin: float === "left" ? "0px 10px 10px 0px" : float === "right" ? "0px 0px 10px 10px" : "0px"
    }
    return (
      <div style={{float: float, width: img === "lightcurve.png" || windowWidth < 900  ? "100%" : width, display: "flex", flexDirection: "column", alignItems: "center", margin: "0px 10px 10px 10px"}}>
        <img style={imgStyle} alt={figure + ", " + credit + ", " + link} src={imgPath}/>
        <p style={{...textStyle, textAlign: "center", fontStyle: "italic", fontSize: "12px"}}>
          {figure}
          <br/>
          {credit ? ["Credit: ", <a style={{...textStyle}} href={link} target="_blank" rel="noreferrer"> {credit} </a>] : ""}
        </p>

      </div>
    )
  }

  return (
    <div style={{
    }}>
      <div onClick={handleClick} style={{
        display: "flex", 
        width: "100%",
        flexDirection: "row", 
        justifyContent: "left", 
        alignItems: "center",
        cursor: "pointer",
        
      }}>
        <p style={{...textStyle, fontSize: '20px'}}>
          <b> {data["title"]} </b>         
        </p>
        {isShowing ? <ArrowDropUp/> : <ArrowDropDown/>}
      </div>
      {isShowing ? data["content"].map((thisContent, index) => {
          return (
            <>
                {isObject(thisContent) ? <p key={index} style={{...textStyle, fontSize: '14px'}}>
                  <ImgWrapper imgData={thisContent["img"]}/>
                  {thisContent["text"]}
                </p> : <p key={index} style={{...textStyle, fontSize: '14px'}}>
                  {thisContent}
                </p>}
                <span style={{display: "block", marginBottom: "2em"}}/>
            </>
          )
      }) : <></>}
    </div>
  )
}

const About = () => {

  const [width, setWidth] = useState(() => window.innerWidth)
  const isMobile = width < 700
  const padding = isMobile ? "25px" : "130px"


  useEffect (() => {
    Object.keys(imageData).forEach(key => {
      const path = "/imgs/" + imageData[key]["img"]
      imageData[key].path = path
    })
  }, [])

  const useStyles = makeStyles((theme) => ({
    aboutContainer: {
      position: "fixed",
      top: "8vh",
      paddingLeft: padding,
      paddingRight: padding,
      // display: "flex",
      // flexDirection: "column",
      overflow: "scroll",
      "-webkit-overflow-scrolling": "touch",
      height: "92vh"
    },
    imgContainer: {
      paddingTop: "20px",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-evenly"
    },
    img: {
      width: "25%",
      margin: "10px 10px 10px 10px"
    },
    space: {
      width: "30px"
    }
  }));
  const classes = useStyles();

  const textStyle = {fontFamily: `"Roboto", "Helvetica", "Arial", "sans-serif"`,
  color: "white"}

  const formattedAboutData = aboutData.map((thisData, i) => <AboutText key={i} data={thisData}/>)

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth)
    window.addEventListener('resize', handleResize)
    return () => {
        window.removeEventListener('resize', handleResize)
    } 
  }, [])

  return (
      <div className={classes.aboutContainer} >
          {formattedAboutData}
          <p style={{...textStyle, textAlign: "center"}}>
              <b>Contributors:</b> 
              {" "}
              <a style={textStyle} href='https://jradavenport.github.io/'>James Davenport</a> {", "}
              <a style={textStyle} href='https://huppenkothen.org'>Daniela Huppenkothen</a> {", "}
              <a style={textStyle} href='http://www.pampin.org'>Juan Pampin</a> {", "}
              <a style={textStyle} href='https://www.linkedin.com/in/james-wenlock'>James Wenlock</a>
          </p>
          <br/>
          <div className={classes.imgContainer}>
            <a className={classes.img} href='https://dxarts.washington.edu'>
              <img alt={"dxarts logo"} width={"100%"} src={imgSrcPath +  imageData['dxarts'].img}/>
            </a>
            <a className={classes.img}  href='https://dirac.astro.washington.edu'>
              <img alt={"dirac"} width={"100%"} src={imgSrcPath +  imageData['dirac'].img}/>
            </a>
            <a  className={classes.img} href='https://www.sron.nl'>
              <img alt={"sron"} width={"100%"} src={imgSrcPath +  imageData['sron'].img}/>
            </a>
          </div>
          <br/>
          <br/>
          <br/>
          <br/>
      </div>
  )
}

export default About