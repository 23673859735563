import {React, useEffect, useState} from "react"
import Stars from "../components/Stars"
import {CircularProgress, makeStyles} from '@material-ui/core'
// import { disableBodyScroll } from 'body-scroll-lock'
import starDataCSV2 from "../data/starData2.csv"
import * as d3 from "d3"
import filterBounds from "../data/filterBounds"
import curatedStarData from "../data/revisedCurated"
import Search from '../components/Search'
import Share from '../components/Share'
import StarFilters from "../components/StarFilters"
import Info from "../components/Info"
import ControlPanel from "../components/ControlPanel"
import Reset from "../components/Reset"
import useWindowSize from "../hooks/useWindowSize"
import ReactTooltip from "../components/MobileReactTooltip"

const filterData = Object.values(filterBounds).map(
  filterBound => filterBound.label
)

const initFilterState = () => {
  let initState = {}
  filterData.forEach((data) => {
    initState = {...initState, [data]: true}
  })
  return initState
}

// const compareStars = (starA, starB) => +(starA.kepid) - +(starB.kepid)

function Sonification({paramsString, setParamsString}) {
  const [starData, setStarData] = useState(NaN)
  const [resetStars, setResetStars] = useState(false)
  const [isLoadingStars, setIsLoadingStars] = useState(true)
  const [starObjects, setStarObjects] = useState({})
  const [width, height] = useWindowSize()
  let margin = 30


  useEffect(() => {
    if (starData.length > 0) {
      setIsLoadingStars(false)
    }
  }, [starData])

  useEffect(() => {
    let result = []

    Object.keys(filterBounds).forEach(key => {
      const thisBound = filterBounds[key];
      const hull = d3.polygonHull(thisBound['points'])
      const centroid = d3.polygonCentroid(hull)
      filterBounds[key] = {
        ...filterBounds[key], 
        hull: hull, 
        centroid: centroid,
        stars: []
      }
    })
    d3.csv(starDataCSV2, function(data) {

        result = [...result, new Promise (resolve => {
          data = {
            kepid: data["kepid"],
            bp_rp: data["bp_rp"],
            abs_gmag: data["abs_gmag"],
            radius: data["radius_val"],
            temp: data["teff"],
            mass: data["mass"]
          }
          resolve(data)
        })]
    }).then(() => {
      Promise.all(result).then(starData => {
        let starObjects = {}
        starData = starData.sort((starA, starB) => +(starA.kepid) - +(starB.kepid))
        const filterKeys = Object.keys(filterBounds)
        const curatedKeys = Object.keys(curatedStarData)
        starData.forEach(star => {
          let sorted = false
          let minDistance = 100000
          let minKey = ""
          let i = 0;
          while (i < filterKeys.length && !sorted ) {
            const key = filterKeys[i]
            const thisBound = filterBounds[key]
            let j = 0
            while (j < curatedKeys.length && !sorted) {
              if (curatedKeys[j] === star.kepid) {
                sorted = true
              }
              j++
            }
            if (sorted) {
              star = {...star, "info": curatedStarData[star.kepid]}
              filterBounds['curated']['stars'] = [...filterBounds['curated']['stars'], star]
            } else if (!sorted && d3.polygonContains(thisBound.hull, [star.bp_rp, star.abs_gmag]) && key !== "curated") {
              filterBounds[key]["stars"] = [...filterBounds[key]["stars"], star]
              sorted = true 
            }

            const centroid = filterBounds[key]["centroid"]

            const distance = Math.sqrt(
              Math.pow((star.bp_rp - centroid[0]), 2)
              + Math.pow((-star.abs_gmag + centroid[1]), 2)
            )
            if (distance < minDistance) {
                minDistance = distance
                minKey = key
            }
            i++
          }
          if (!sorted) {
            filterBounds[minKey]["stars"] = [...filterBounds[minKey]["stars"], star]
          }
          starObjects = {...starObjects, [star.kepid]: star}
        })
        setStarObjects(starObjects)
        setStarData(starData)
      })
    })
    return () => {}
  }, [])


  if (width > 700) {
    margin = margin + 100
  }

  const useStyles = makeStyles((theme) => ({
    container: {
      width: "100vw",
      height: "100vh",
      position: "relative" 
    },
    menuDivBack: {
      width: "150px",
      height: "40px",
      position: "fixed",
      zIndex: "12",
      opacity: "0.5",
      background: "black",
      left: margin + 1,
      top: height - 201,
    },
    menuDivFront: {
      width: "150px",
      height: "40px",
      position: "fixed",
      zIndex: "13",
      display: "flex",
      justifyContent: "space-evenly",
      alignItems: "center",
      left: margin + 1,
      top: height - 201,
    },
    menuBtn: {
      position: "absolute",
      top: "10px",
      left: "10px",
      "&:hover": {
        cursor: "pointer"
      }
    },
    helpBtn: {
      position: "absolute",
      bottom: "10px",
      right: "20px",
      "&:hover": {
        cursor: "pointer"
      },
      zIndex: 1
    },
    settingsBtn: {
      position: "absolute",
      bottom: "10px",
      right: "60px",
      "&:hover": {
        cursor: "pointer"
      },
      zIndex: 1
    },
    starBtn: {
      position: "absolute",
      bottom: "10px",
      right: "100px",
      "&:hover": {
        cursor: "pointer"
      },
      zIndex: 1
    },
    loadingContainer: {
      display: "flex",
      justifyContent: "center",
      width: "100vw",
      height: "100vh",
      alignItems: "center",
      flexDirection: "column"
    }
  }));
  const classes = useStyles();


  const LoadingScreen = () => {
    return (
      <div style={{
        width: "80%", 
        height: "100%", 
        marginLeft: "10%", 
        display: "flex", 
        flexDirection: "column", 
        justifyContent: "center", 
        alignItems: "center"
      }}>
        <p style={{fontFamily: "Roboto", fontSize: "20px", textAlign: "center"}}> 
          Loading
        </p>
        <CircularProgress color={"inherit"} />
        <p style={{fontFamily: "Roboto", fontSize: "16px", textAlign: "center"}}> 
          If on mobile, make sure your phone is not silenced.
        </p>
      </div>
    )
  }

  const ResizeWarning = () => {
    return (
      <div style={{width: "80%", height: "100%", marginLeft: "10%", display: "flex", justifyContent: "center", alignItems: "center"}}>
        <p style={{fontFamily: "Roboto", fontSize: "16px", textAlign: "center"}}> 
          Browser window is not large enough. 
          <br/> 
          To experience starsounder, please flip your phone vertically or expand your window size.
        </p>
      </div>
    )
  }

  const StarDisplay = () => {
    return (
      <>
        <div className={classes.menuDivBack}/>
        <div className={classes.menuDivFront}>
            <Info 
              starObjects={starObjects}
            />          
          <div data-tip='share' data-for="sonification">
            <Share/>
          </div>
          <Search 
            starData={starData} 
            filterBounds={filterBounds}
            initFilterState={initFilterState()} 
            filterData={filterData}
          />
          <StarFilters
            filterBounds={filterBounds}
            initFilterState={initFilterState()}
            filterData={filterData}
          />
          <div data-tip='reset diagram' data-for="sonification">
            <Reset
              initFilterState={initFilterState()}
            />
          </div>
        </div>
        <Stars
          resetStars={resetStars}
          setResetStars={setResetStars}
          paramsString={paramsString}
          setParamsString={setParamsString}
          isLoadingStars={setIsLoadingStars}
          starData={starData}
          starObjects={starObjects}
          filterBounds={filterBounds}
          initFilterState={initFilterState()}
          filterData={filterData}
          margin={margin}
        />
        <ControlPanel/>
        <ReactTooltip id="sonification"/>
      </>
    )
  }

  return (
    (height < 480 || width < 260) ? <ResizeWarning/> : isLoadingStars ? <LoadingScreen/> : <StarDisplay/>
  )
}

export default Sonification;