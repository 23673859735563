import './App.css';
import {React, useEffect, useState} from "react"
import Sonification from "./pages/Sonification"
import About from "./pages/About"
import Contact from "./pages/Contact"
import Menu from "./components/Menu"
import { makeStyles} from '@material-ui/core';
import {Route, BrowserRouter, Routes} from "react-router-dom"


function App() {

  const useStyles = makeStyles((theme) => ({
    container: {
        width: "100vw",
        height: "100vh",
        position: "relative" 
    },
    menuBtn: {
      position: "absolute",
      top: "20px",
      left: "30px",
      "&:hover": {
        cursor: "pointer"
      }
    }
  }));

  const classes = useStyles()
  const [paramsString, setParamsString] = useState(
    new URL(window.location.href).searchParams.toString()
  )

  useEffect(() => {
    console.log(paramsString)
  }, [paramsString])

  return (
      <BrowserRouter>
        <div id="container" className={classes.container}>
          <Menu/>
          <Routes>
            <Route path="/" element={<Sonification paramsString={paramsString} setParamsString={setParamsString}/>}/>
            {/* <Route path="/faq" element={<FAQ/>}/> */}
            <Route path="/about" element={<About/>}/>
            <Route path="/contact" element={<Contact/>}/>
          </Routes>
        </div>
      </BrowserRouter>
  );
}

export default App;
