import {makeStyles} from "@material-ui/core"
import useWindowSize from "../hooks/useWindowSize";

const About = () => {
  const [width] = useWindowSize()

  const isMobile = width < 700
  const padding = isMobile ? "25px" : "130px"

  const useStyles = makeStyles((theme) => ({
    container: {
      paddingTop: "100px",
      paddingRight: padding,
      paddingLeft: padding,
      display: "flex",
      flexDirection: "column",
    }
  }));

  const classes = useStyles();

  const textStyle = {fontFamily: `"Roboto", "Helvetica", "Arial", "sans-serif"`,
  color: "white", textAlign: "center", fontSize: "16px"}

  return (
      <div className={classes.container} >
          <p style={textStyle}>
            <b>General</b>
            <br/>
            <a 
              style={textStyle} 
              href="mailto: contact@starsounder.space?subject=Starsounder General Inquiry"
            > 
              contact@starsounder.space
            </a>
            <br/>
            <br/>
            <b>Sonification</b>
            <br/>
            <a 
              style={textStyle} 
              href="mailto: juan@starsounder.space?subject=Starsounder Sonification Inquiry"
            > 
              juan@starsounder.space
            </a>
            <br/>
            <br/>
            <b>Astronomy</b>
            <br/>
            <a 
              style={textStyle} 
              href="mailto: daniela@starsounder.space?subject=Starsounder Astronomy Inquiry"
            > 
              daniela@starsounder.space
            </a>
            <br/>
            <a 
              style={textStyle} 
              href="mailto: jim@starsounder.space?subject=Starsounder Astronomy Inquiry"
            > 
              jim@starsounder.space
            </a>
            <br/>
            <br/>
            <b>Bug Reports and Web Dev</b>
            <br/>
            <a 
              style={textStyle} 
              href="mailto: james@starsounder.space?subject=Starsounder Web Inquiry"
            > 
              james@starsounder.space
            </a>
            <br/>
            <br/>
            <b>Press</b>
            <br/>
            <a 
              style={textStyle} 
              href="mailto: press@starsounder.space?subject=Starsounder Press Inquiry"
            > 
              press@starsounder.space
            </a>
            <br/>
            <br/>
          </p>
      </div>
  )
}

export default About