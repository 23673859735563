const curatedStarData = {
  5559631: [
    "This star is also known as V783 Cyg, and is an RR Lyrae variable star. This class of stars is named after the prototypical example RR Lyrae, first discovered by the Scottish astronomer Williamina Fleming at Harvard observatory in 1901. They are stars that were once stars similar in mass to our sun, but with fewer heavy elements compared to our sun. RR Lyrae stars were once like our Sun, but have exhausted their hydrogen and now are producing energy through the fusion of helium into carbon, oxygen, and nitrogen.",
    "RR Lyrae stars like V783 Cyg are interesting because they produce very regular pulsations in brightness. These pulsations are caused by forces of gravity and radiation (the emission of light) acting in opposite directions: the radiation of the star pushes gas outwards, whereas gravity pulls it inwards. If gravity exceeds the pressure exerted by radiation, the star contracts. As it does so, however, it also heats up, which increases the radiation pressure (and makes it brighter to us as an observer) until this pressure exceeds gravity. At this point, radiation pressure pushes the gas outwards, the star expands, and cools as it does so (and becomes dimmer to us). When the star has expanded enough for gravity to once again become the strongest force, the cycle begins anew.",
    "V783 Cyg shows the brightness variations due to this effect, but not only those. Some RR Lyrae stars show additional modulations in brightness over days to weeks, called the Blazhko effect, first observed by Sergey Blazhko in 1907.",
    "In the sonification of the Kepler data of the star, you can hear a constant tone around 400 Hz caused by the regular pulsations in brightness as well as slower periodic changes in amplitude (the Blazhko effect)."
  ],
  7198959: [
    "RR Lyrae was first discovered by the Scottish astronomer Williamina Fleming at Harvard observatory in 1901. It is a prototypical example of a whole class of stars that were once stars similar in mass to our sun, but with fewer heavy elements compared to our sun. While our sun is powered by fusion of hydrogen into helium to produce the light that we see, the much older RR Lyrae stars have already exhausted their hydrogen and are now producing energy through the fusion of helium into carbon, oxygen and nitrogen.",
    "RR Lyrae stars are interesting because they produce very regular pulsations in brightness. These pulsations are caused by forces of gravity and radiation (the emission of light) acting in opposite directions: the radiation of the star pushes gas outwards, whereas gravity pulls it inwards. If gravity exceeds the pressure exerted by radiation, the star contracts. As it does so, however, it also heats up, which increases the radiation pressure (and makes it brighter to us as an observer) until this pressure exceeds gravity. At this point, radiation pressure pushes the gas outwards, the star expands, and cools as it does so (and becomes dimmer to us). When the star has expanded enough for gravity to once again become the strongest force, the cycle begins anew.",
    "In the sonification of the Kepler data of the star, you can hear a constant tone around 440 Hz (a middle A on the piano). This tone is caused by the regular pulsations in brightness of the star, and is modulated slowly in amplitude due to the expansion and contraction process described above.",
    "The sonification for this star is shorter compared to many of the other stars, because there is less data available for this star: it is very bright, which makes it harder for our modern, sensitive telescopes to observe."
  ],
  7446357: {
    text: [
    "This star, also known as V1504 Cyg, is a Dwarf Novae. These are stars that have dramatic, brief increases in brightness. During this “outburst”, they can even become visible to the naked eye, hence the name “novae” (new). Dwarf novae are stars in binary systems, that is, they actually consist of two stars orbiting around each other. One star is generally a white dwarf--- the compact core of a dead star---and a more common main sequence star (similar to our sun). For systems like V1504 Cyg the two stars are very close to one another: V1504 Cyg has an orbital period of only 1.6 hours (compare that to Earth’s orbit around the sun, which takes a year)! ",
    "In many binary systems that close, the white dwarf’s gravity will attract gas from its companion. This gas settles first into a disk around the white dwarf, and will slowly spiral inwards before it falls onto the white dwarf’s surface. Because this is not a perfectly regular process, sometimes this disk can become unstable, which will increase the temperature, and this change in temperature makes it appear much brighter to us as the far-away observer. Dwarf nova outbursts recur periodically, on timescales of days to decades. In some systems like V1504, one can also observe what are called “superoutbursts”, which are both longer and brighter than regular outbursts. During these superoutbursts, periodic humps in brightness called “superhumps” appear with a frequency slightly longer than the time it takes for the two stars to do one full orbit. These are caused by the complex physical processes going as the gas falling in the disk around the white dwarf is pulled in this direction and that direction due to the gravitational forces of the two stars.",
    "The sonification of this binary system is extremely rich and has many layers of sound. The two most prominent layers are a sequence of high-pitched impulses (around 2 per second) which is produced by the superhumps, and a sequence of fast unpitched rhythmic impulses (around 10 per second) which is produced by the regular outbursts. Listening more carefully you can also hear a high-pitched continuous tone starting at around 1 second and ending at around 2.5 seconds; there is also a fainter second sustained tone of lower pitch that starts at around 3 seconds. These tones are produced by negative superhump signals which occur when the accretion disk tilts out of the orbital plane."
    ],
    links: [
      {
        label: "Sonification Of Kepler Field",
        link: "https://ntrs.nasa.gov/api/citations/20130013467/downloads/20130013467.pdf"
      }
    ]
  },
  8451868: [
    "This object, also called GJ 1245AB is actually not a single star, but a system of three stars: the stars A and B orbit around each other, and A has an additional smaller companion ( C ). All three are small stars called M dwarfs (or more commonly known as a red dwarf). This name designates the smallest and coolest stars in the large family of stars called the main sequence. They are much smaller than our sun,each  less than half its mass. Because C is so much smaller and dimmer than the other two stars in the system, it’s almost impossible to observe, hence the designation “AB” in the system’s name.",
    "Despite the differences, there are some observational similarities to our sun. In our sun, we can observe how changes in its magnetic field lead to phenomena like sunspots (which appear as dark spots on the bright disk of the sun in images) and solar flares (rapid brightening of the sun over a few minutes). Because the sun is (comparatively) close, we have a large body of images and even movies of these phenomena. In red dwarfs, which are too far away and too small to create useful images and movies of their surface, we have to infer the presence through their light curves (a list of the measured brightness of the whole star at different points in time). Star spots cause a regular dimming of the star’s brightness, flares cause a very rapid, but very irregular brightening of the star.",
    "In the sonification of this star you can hear multiple sinusoidal sounds. The loudest of these sounds has a frequency of 950 Hz which corresponds to the rotation period of star A in the system that is 0.2 days (a harmonic of this sound can also be heard at 1900 Hz). There is a softer sinusoidal sound at 350 Hz, this sound corresponds to the rotation period of star B in the system, that is 0.7 days. Crackling sound is flares, happening once every couple hours on both of these stars.",
    "For another example of a single star of this type, listen to 9726699."
  ],
  9246715: [
    "This is an eclipsing binary star system. In an eclipsing binary system, two stars orbit around each other, and pass through our line of sight so that for a short while, one is in front of the other, much like what happens in a solar eclipse, where the moon is between us and the sun. In practice, this leads to a dip in brightness (much like it gets darker during a solar eclipse) that we can see in the light curve, and hear as a slow rhythmic heartbeat in the sonification. The two stars in the system are both red giant stars, so stars that have burned up all of their hydrogen and are now in their late helium-burning phase. In the HR diagram, this means they have moved off the diagonal main sequence and onto the “red giant branch”, where they become significantly larger and redder than before.",
    "The stars pass in front of each other, causing short dips in the brightness. We hear these eclipses as a slow rhythmic heartbeat."
  ],
  9726699: [
    "This star, also called GJ 1243, is an M dwarf (also known as a red dwarf star). These small stars are only 10-20% the size of our Sun, but make up more than 70% of the stars in the Milky Way.",
    "Despite the differences, there are some observational similarities to our sun. In our sun, we can observe how changes in its magnetic field lead to phenomena like sunspots (which appear as dark spots on the bright disk of the sun in images) and solar flares (rapid brightening of the sun over a few minutes). Because the sun is (comparatively) close, we have a large body of images and even movies of these phenomena. In red dwarfs, which are too far away and too small to create useful images and movies of their surface, we have to infer the presence through their light curves (a list of the measured brightness of the whole star at different points in time). Star spots cause a regular dimming of the star’s brightness, flares cause a very rapid, but very irregular brightening of the star.",
    "In the sonification of this star you can hear a continuous tone with a frequency of 422 Hz which corresponds to the spot rotation period of the star that is 0.6 days (a harmonic of this sound can also be heard at 844 Hz). Crackling sound is flares, happening once every couple hours on this star.",
    "For another example of a system that doesn’t contain one, but three of these stars, listen to 8451868."
  ],
  9850387: {
    text: [
      "This star is an eclipsing binary star system: it actually consists of two stars orbiting around each other,  much like the Earth orbits around the Sun. They orbit such that occasionally, one star is between us and the other star. This is similar to a solar eclipse, where the moon is between the Sun and the Earth, though unlike any object in our own solar system, the two stars in this binary system are much closer together: a year on one of those stars (if you could go there) would only last 2.75 days! There is probably a third star, orbiting around the binary system in a much wider orbit, completing a round once every 671 days.", 
      "Many stars pulsate in their brightness, that is, their brightness varies periodically. These brightness variations are caused by internal ringing within the star (think of a bell being struck, or the way earthquakes travel through the Earth). This ringing depends on a star’s mass, and also how young or old it is. The pulsations seen in the Kepler data from this system suggest that both stars in the binary system are what we call pre-main sequence stars. That means they are very young, with an age of about 7 million years (compare this to the sun, which has been around for close to 4.5 billion years!). Observing stars like these in binary systems is exciting, because their binary nature helps us better constrain their physical properties, and their young age is exciting, because they help us understand how stars form and evolve.",
      "In this example you can hear a continuous tone with a frequency of 182 Hz corresponding to the dips in brightness caused by the eclipses happening every 2.75 days. The shape and periodicity of these dips produce a very bright sound with a rich harmonic spectrum."
    ],
    links: [
      {
        label: "A Pre-main-sequence γ Dor-δ Sct Hybrid with Extremely Slow Internal Rotation",
        link: "https://iopscience.iop.org/article/10.3847/1538-4357/ab8ad5"
      }
    ]
  },
  9970396: {
    text: [
      "KIC 9970396 is an old star. After living most of its life burning hydrogen to helium (which provides the energy to make the star shine), it has exhausted all the helium inside its core. Once this happens, an exciting phase in the star’s life sets in: it starts burning helium in its core to carbon, oxygen and nitrogen, and continues to burn hydrogen in a shell around the core where it’s still available. These processes cause a lot of mixing of the internal structure of the star, and they also make the star expand and get brighter: this star is just a tiny bit heavier than the sun, but almost 29 times as bright! Once this happens, stars are no longer on the diagonal in the HR diagram we call the main sequence, but have moved off vertically and to the right into what we call the “red giant branch”.",
      "Much like many other stars, including our sun, the physical processes (including the mixing of materials in the inside of the star) lead to pulsations in the observed light: it gets periodically brighter and dimmer. These pulsations are useful to researchers, because we can connect them to models of the physical processes that we think produce them. This allows us to learn about the interior of the star, which we can’t directly observe, and helps us understand the structure and evolution of stars in general.",
      "In this example you can hear impulses with a heartbeat rhythm. Stronger impulses happen every second, followed by a fainter impulse 0.4 seconds after them, these impulses correspond with the periodic changes in brightness mentioned above. There is also a group of higher sustained sounds that has a peak around 1878 Hz."
    ],
    links: [
      {
        label: "Determining the size of the helium core of KIC 9970396 using asteroseismology",
        link: "https://academic.oup.com/mnras/article/494/1/511/5814994"
      }
    ]
  },
  10666592: [
    "This star is also referred to as HAT-P-7, and it’s what we call an F-type star, which means it’s just a little bit heavier than the sun (somewhere between 1.07 and 1.4 times the mass of the sun), and a little bit hotter, too. This moves it to a place in the HR diagram just to the upper left of the sun.",
    "HAT-P-7 is particularly interesting because it harbors at least one exoplanet, HAT-P-7b. This exoplanet was found by observing dips in brightness as the planet moved between us and its parent star. This is analogous to the drop in daylight during a solar eclipse, except the observed drop is much more miniscule, of the order of 1/1000 of the star’s total brightness. Nevertheless, some earth-bound telescopes and space missions (like Kepler) possess the sensitivity to pick up these brightness changes.",
    "HAT-P-7b is very big compared even to Jupiter, the largest planet in the solar system: it’s 1.8 times as massive as Jupyter, but much closer to the sun: it orbits its parent star every 2.20 days (compare that to the 12 years it takes Jupiter to orbit the sun)! It is also the darkest planet we’ve ever observed, and absorbs nearly 97% of the light it gets from its parent star (Jupiter only absorbs about half the light it receives and reflects the rest).",
    "In this example you can hear a continuous tone with a frequency of 113 Hz corresponding to the dips in brightness caused by the eclipses happening every 2.20 days. The shape and periodicity of these dips produce a very bright sound with a rich harmonic spectrum."
  ],
  10748390: {
    text: [
      "This star, also called HAT-P-11, is what we call a K-type star: it has around 0.8 times the mass of the sun, but only a quarter of the sun’s brightness. The star harbors at least two exoplanets (that we know of): HAT-P-11b is similar to Neptune, but much closer to its parent star. A year on this planet lasts just under five days! A second planet exists much further out, on a nine-(Earth-)year orbit. HAT-P-11b was detected using the transit method. This method observes the brightness of stars over a long time, and measures the dip in that brightness when the planet traverses the line of sight between us and the star, blocking out some of the starlight in the process. By measuring the recurrence, depth and length of that dip we can gain important information about the planet’s orbit and size.",
      "The star itself also shows starspots, like our sun does. These starspots rotate around the star and therefore also cause periodic dips in brightness. We can use these dips to determine the axis of rotation of the star, and for this system led to an interesting discovery: the star’s axis of rotation is quite significantly offset from the plane where its planets lie, by as much as 100 degrees! Compare this to our sun, which only has an obliquity of 6 degrees.",
      "In this example you can hear a continuous low tone with a frequency of 50 Hz corresponding to the dips in brightness. The shape and periodicity of these dips produce a very bright sound with a rich harmonic spectrum. These impulses are mounted on a slower and more erratic modulation of sound with a frequency of around 17 Hz which you can hear as a low rumble corresponding to half of the rotation period of the star, these correspond to the rotation frequency of starspots. There is also a subtle high-frequency “whistling” that changes throughout the data, which is likely due to small differences in the data quality throughout the Kepler mission as the spacecraft changed orientation."
    ],
    links: [
      {
        label: "Starspots and spin-orbit alignment for Kepler cool host stars",
        link: "https://arxiv.org/pdf/1211.2002.pdf"
      }
    ]
  },
  11604781: {
    text: [
      "KIC 11604781 is a white dwarf, the final stage of evolution of a low-mass star (where low mass refers to stars of about eight times the mass of the sun or less). At the end of its life, a star has burned the hydrogen in its core (on the main sequence) and subsequently also its helium (on the red giant branch). It then sheds its outer layers into what is called a “planetary nebula” (a bit of a misnomer, as there are no planets involved) and retains a hot, small core of carbon, nitrogen and oxygen, called a white dwarf. This will be the ultimate fate of our own sun (though in about 4.5 billion years, so no need to panic!).",
      "While the evolution of the star mentioned above (main sequence to red giant to white dwarf) is understood in broad strokes, there are many questions left to answer about the formation process of a white dwarf. One thing that can help is studying the rotation of white dwarf (how fast it spins about its own axis) and compare this to the rotation of main-sequence stars like our sun. We can find out about the rotation by studying periodic modulation in brightness, which also leave an imprint on our sonification in the form of a continuous tone (at high frequencies, i.e. modulations over a short time scale) or as periodic changes in sound loudness (for modulations that take longer).",
      "This white dwarf shows with a surprisingly low frequency sound (around 50Hz). There’s also a white-noise like component, due to the asteroseismic noise on minutes-to-hours timescales."
    ],
    links: [
      {
        label: "Kepler and the seven dwarfs: detection of low-level day-timescale periodic photometric variations in white dwarfs",
        link: "https://arxiv.org/abs/1409.5129"
      }
    ]
  },
  3749404: [
    "This is an example of what we call a “heartbeat star”. This term does not in fact describe a single star, but a binary system of two stars orbiting around each other. In heartbeat stars, the orbit is what we call “eccentric”, which is to mean skewed: instead of orbiting around each other in a near-circular orbit, the orbits are ellipses. In elliptical orbits, the stars will sometimes get very close to each other. Because stars are big balls of gas (so kind of squishy) and also very massive (so have lots of gravity), when they get close enough, the star’s shape gets deformed through tides (similar to how the oceans react to the moon’s gravity) and also from the light they receive from their companion star. We can see this effect as peaks in the light curve that look a bit like a cardiogram, the representation of your heart beat on a heart monitor. This is where they get the name from.",
    "In this sonification you can hear a chopping sound with a frequency of 12 impulses per second due to the actual heartbeats. A higher continuous tone of 259 Hz corresponds to the ringing of the stars as they pass each other."
  ],
  8719324: [
    "This is an example of what we call a “heartbeat star”. This term does not in fact describe a single star, but a binary system of two stars orbiting around each other. In heartbeat stars, the orbit is what we call “eccentric”, which is to mean skewed: instead of orbiting around each other in a near-circular orbit, the orbits are ellipses. In elliptical orbits, the stars will sometimes get very close to each other. Because stars are big balls of gas (so kind of squishy) and also very massive (so have lots of gravity), when they get close enough, the star’s shape gets deformed through tides (similar to how the oceans react to the moon’s gravity) and also from the light they receive from their companion star. We can see this effect as peaks in the light curve that look a bit like a cardiogram, the representation of your heart beat on a heart monitor. This is where they get the name from.",
    "In this sonification you can hear a chopping sound of 24 impulses per second due to the actual heartbeats of the eclipse. A higher continuous tone of 259 Hz corresponds to the ringing of the stars as they pass each other. There is also a lower component at 80 Hz which corresponds to the rotation of the stars."
  ],
  5034333: [
    "This is an example of what we call a “heartbeat star”. This term does not in fact describe a single star, but a binary system of two stars orbiting around each other. In heartbeat stars, the orbit is what we call “eccentric”, which is to mean skewed: instead of orbiting around each other in a near-circular orbit, the orbits are ellipses. In elliptical orbits, the stars will sometimes get very close to each other. Because stars are big balls of gas (so kind of squishy) and also very massive (so have lots of gravity), when they get close enough, the star’s shape gets deformed through tides (similar to how the oceans react to the moon’s gravity) and also from the light they receive from their companion star. We can see this effect as peaks in the light curve that look a bit like a cardiogram, the representation of your heart beat on a heart monitor. This is where they get the name from.",
    "In this sonification you can hear a rough low sound of 36 impulses per second, due to the actual heartbeats of the eclipse. A higher sound, which sounds like a bell, is composed of partials with peaks around 970, 1394, and 2380 Hz; these correspond to the ringing of the stars as they pass each other."
  ],
  9899216: [
    "This is an example of what we call a “heartbeat star”. This term does not in fact describe a single star, but a binary system of two stars orbiting around each other. In heartbeat stars, the orbit is what we call “eccentric”, which is to mean skewed: instead of orbiting around each other in a near-circular orbit, the orbits are ellipses. In elliptical orbits, the stars will sometimes get very close to each other. Because stars are big balls of gas (so kind of squishy) and also very massive (so have lots of gravity), when they get close enough, the star’s shape gets deformed through tides (similar to how the oceans react to the moon’s gravity) and also from the light they receive from their companion star. We can see this effect as peaks in the light curve that look a bit like a cardiogram, the representation of your heart beat on a heart monitor. This is where they get the name from.",
    "In this sonification you can hear a rough low sound of 23 impulses per second due to the actual heartbeats of the eclipse."
  ],
  11551430: [
    "In the sonification of this star you can hear a clear low tone at around 60Hz. Another pair of tones with frequencies of 30 and 120 Hz pulsate in amplitude throughout the sonification. The crackling noise corresponds to star flares."
  ],
  11145123: [
    "Despite often being depicted that way, stars are generally not completely spherical. Because they are big, squishy balls of gas that generally spin around their own axis, stars like our sun are slightly flattened at the poles and wider at the equator. Except for this specimen here, KIC11145123, which researchers believe is the roundest natural object ever found.",
    "The researchers actually used the same brightness measurements we used for this sonification in order to make that measurement. They used periodic variations in brightness generated at different distances from the star’s equator to measure the stellar radius at these different distances, and found that it doesn’t change all that much, at least not compared to other stars.",
    "You can listen to the symphony of stellar brightness variations yourself in this example. You can hear 3 harmonic groups, one with center at around 350 Hz (it sounds like a choir!), a second one with center at around 4490 Hz, and a last one with a center frequency of 7750 Hz."
  ]
}

export default curatedStarData