import {React, useEffect, useState, useRef} from "react"
import PlayBtn from '@material-ui/icons/PlayCircleFilled';
import PauseBtn from '@material-ui/icons/PauseCircleFilled';
import RepeatBtn from '@material-ui/icons/RepeatOutlined';
import {makeStyles} from '@material-ui/core'
import WaveSurfer from "wavesurfer";
import Volume from "./Volume";
import { useSearchParams } from "react-router-dom";
import Playback from "./Playback";
import FastRewind from '@material-ui/icons/SkipPrevious';
import ReactTooltip from './MobileReactTooltip';

// const defaultEQ = {'lo': 0, 'mid': 0, 'hi': 0}
const fetchSearchParam = (param, defaultParam, searchParams) => {
  if (searchParams.has(param)) {
    return (searchParams.get(param))
  }
  return defaultParam
}

function ControlPanel() {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoadingBuffer, setIsLoadingBuffer] = useState(false)
  const [loadingProgress, setLoadingProgress] = useState(0)
  const [waveSurfer, setWaveSurfer] = useState(NaN)
  const [width, setWidth] = useState(window.innerWidth)
  const [height, setHeight] = useState(window.innerHeight)
  const [isRepeating, setIsRepeating] = useState(true)
  const [searchParams] = useSearchParams()

  const margin = width < 700 ? 30 : 130
  const sliderWidth = width < 700 ? "30%" : 200
  const sliderHeight = 30

  const [starID, setStarID] = useState(() => fetchSearchParam('starID', '4049174', searchParams))
  // const [volume, setVolume] = useState(() => fetchSearchParam('volume', 1.0, searchParams))
  // const [eq] = useState(() => {
  //   return {
  //     lo: fetchSearchParam('lo', defaultEQ.lo, searchParams),
  //     mid: fetchSearchParam('mid', defaultEQ.mid, searchParams),
  //     hi: fetchSearchParam('hi', defaultEQ.hi, searchParams)
  //   }
  // })
  // const [playback, setPlayback] = useState(() => fetchSearchParam('playback', 1.0, searchParams))

  const waveformRef = useRef();
  const wavesurferRef = useRef()
  const repeat = useRef(isRepeating)
  // const eqRef = useRef(eq)

  const handlePlay = () => {
    if (waveSurfer) {
      waveSurfer.playPause()
      setIsPlaying(prevState => !prevState)
    }
  }

  // useEffect(() => {
  //   // if (waveSurfer) {
  //   //   waveSurfer.setVolume(volume)
  //   // }
  // }, [volume, waveSurfer])

  // useEffect(() => {
  //   if (waveSurfer) {
  //     waveSurfer.setPlaybackRate(playback)
  //   }
  // }, [playback, waveSurfer])

  // useEffect(() => {
  //   eqRef.current = eq
  // }, [eq])

  // useEffect(() => {
  //   if (waveSurfer) {
  //     waveSurfer.getFilters().forEach(filter => {
  //       if (filter.type === "lowshelf") {
  //         filter.gain.value = eq.lo
  //       } else if (filter.type === "peaking") {
  //         filter.gain.value = eq.mid
  //       } else {
  //         filter.gain.value = eq.hi
  //       }
  //     })
  //   }
  // }, [eq, waveSurfer])

  useEffect(() => {
    setStarID(+fetchSearchParam('starID', '4049174', searchParams))
    // setVolume(+fetchSearchParam('volume', 1.0, searchParams))
    // setEQ({
    //   lo: +fetchSearchParam('lo', defaultEQ.lo, searchParams),
    //   mid: +fetchSearchParam('mid', defaultEQ.mid, searchParams),
    //   hi: +fetchSearchParam('hi', defaultEQ.hi, searchParams)
    // })
    // setPlayback(+fetchSearchParam('playback', 1.0, searchParams))
  }, [searchParams])

  useEffect(() => {
    const wavesurfer = WaveSurfer.create({
      container: waveformRef.current,
      height: 40
    });

    wavesurfer.on("finish", () => setIsPlaying(false))
    wavesurfer.on("loading", (progress) => setLoadingProgress(progress))
    wavesurfer.on("ready", () => {
    //   let EQ = [
    //     {
    //       f: 500,
    //       type: 'lowshelf'
    //     },
    //     {
    //       f: 1000,
    //       type: 'peaking'
    //     },
    //     {
    //       f: 2000,
    //       type: 'peaking'
    //     },
    //     {
    //       f: 4000,
    //       type: 'highshelf'
    //     },
    // ];
    //   const getGain = (type) => {
    //     if (type === 'lowshelf') {
    //       return eqRef.current.lo
    //     } else if (type === 'peaking') {
    //       return eqRef.current.mid
    //     }
    //     return eqRef.current.hi
    //   }
    //   // Create filters
    //   let filters = EQ.map(function(band) {
    //       let filter = wavesurfer.backend.ac.createBiquadFilter();
    //       filter.type = band.type;
    //       filter.gain.value = getGain(band.type);
    //       filter.Q.value = 1;
    //       filter.frequency.value = band.f;
    //       return filter;
    //   });

    //   // Connect filters to wavesurfer
    //   wavesurfer.backend.setFilters(filters);
      setIsLoadingBuffer(false)
      setLoadingProgress(0)
    })
    wavesurfer.on("finish", () => {
      if (repeat.current) {
        wavesurfer.playPause()
        setIsPlaying(true)
      }
    })
    
    // wavesurfer.setMute(true)
    wavesurferRef.current = wavesurfer
    const spaceDown = (e) => {
      if (e.code === "Space" && !wavesurferRef.current.isLoadingBuffer) {
        wavesurferRef.current.playPause()
        setIsPlaying(prevState => !prevState)
      }
    }
    const handleResize = () => {
      setWidth(window.innerWidth)
      setHeight(window.innerHeight)
    }
    window.addEventListener('keydown', spaceDown)
    window.addEventListener('resize', handleResize)
    setWaveSurfer(wavesurfer)
    return () => {
      if (wavesurferRef.current.isPlaying()) {
        wavesurferRef.current.pause()
      }
      window.removeEventListener('keydown', spaceDown)
      window.removeEventListener('resize', handleResize)

    }
  }, [])

  // const controlContainerStyle = {
  //   https://redirect.teleparty.com/join/0597f7a6757ca5d1
  // }

  const useStyles = makeStyles(() => ({
    playContainer: {
      position: "fixed",
      width: 50,
      left: width / 2 - 25,
      height: 50,
      top: height - 87.5 + 10,
      display: "flex",
      alignItems: "center",      
      justifyContent: "center",      
      zIndex: 19
    },
    volumeContainer: {
      position: "fixed",
      width: sliderWidth,
      height: 120,
      top: height - 117.5 - sliderHeight,
      left: "" + margin + "px",
      display: "flex",
      alignItems: "center",      
      justifyContent: "center",  
      zIndex: 28
    },
    playbackContainer: {
      position: "fixed",
      width: sliderWidth,
      height: 120,
      top: height - 117.5 - sliderHeight,
      right: "" + margin + "px",
      display: "flex",
      alignItems: "center",      
      justifyContent: "center",        
      zIndex: 20
    },
    backBtn: {
      position: "fixed",
      bottom: 35,
      left: width / 2 - 65,
      zIndex: 26,
      padding: "2px 2px 2px 2px",
      borderStyle: "solid",
      borderColor: "white",
      borderRadius: "50%",
      borderWidth: "2px",
      color: "white",
      "&:hover": {
        cursor: "pointer"
      },
    },
    repeatBtn: {
      position: "fixed",
      bottom: 35,
      right: width / 2 - 65,
      zIndex: 26,
      padding: "2px 2px 2px 2px",
      borderStyle: "solid",
      borderColor: isRepeating ? "white" : "gray",
      borderRadius: "50%",
      borderWidth: "2px",
      color: isRepeating ? "white" : "gray",
      "&:hover": {
        cursor: "pointer"
      },
    },
    btn: {
      "&:hover": {
        cursor: "pointer"
      },
    },
    starIDDiv: {
      position: "fixed",
      top: 20,
      right: margin,
      width : 150,
      height: 30,
      background: "black",
      opacity: 0.0,
      zIndex: 25
    },
    starIDTextDiv: {
      position: "fixed",
      top: 20,
      right: margin,
      textAlign: "right",
      width : 150,
      height: 30,
      zIndex: 26,
      fontSize: '20px',
    }
  }));

  const classes = useStyles();

  const Play = ({isPlaying, isLoadingBuffer, handlePlay, progress}) => {
    const divStyle = {
      width: "100%",
      height: '60px',
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    }

    const btnStyle = {
      fontSize: "60px"
    }

    if (isLoadingBuffer) {
      return (
        <div style={divStyle}> 
          <p style={{textAlign: "center", fontFamily: "Roboto", fontSize: "12px"}}> Loading: {
            progress < 10 ? "0" + progress : progress
          }% </p>
        </div>
      )
    } else {
      return (
        <div style={divStyle}> 
          {!isPlaying ? <div data-tip="play">
            <PlayBtn
              style={btnStyle}
              className={classes.btn}
              onClick={handlePlay}
            />             
          </div>
          : <div data-tip="pause">
              <PauseBtn 
                style={btnStyle}
                className={classes.btn}
                onClick={handlePlay}
            />            
          </div>}
        </div> 
      )
    }
  }

  useEffect(() => {
    if (starID && waveSurfer) {
      waveSurfer.pause()
      setIsPlaying(false)
      setIsLoadingBuffer(true)
      import("../data/wavs_24000/" + starID + "_long_24k.wav").then(wav => {
        waveSurfer.load(wav.default)
      });
    }
  }, [starID, waveSurfer, width])


  const LoadingBar = ({progress}) => {
    return (
      <div style={{
        width: (width - (margin * 2) - 1.5) * (progress / 100),
        height: 2,
        position: "fixed", 
        top: height - 140,
        left: margin - 0.5,
        background: "white"
      }}>
      </div>
    )
  }

  const handleRepeat = () => setIsRepeating(prevState => {
    repeat.current = !prevState
    return repeat.current
  })

  const handleBack = () => {
    if (wavesurferRef.current) {
      wavesurferRef.current.seekTo(0)
    }
  }

  return (
    <>
      <div style={{
          width: width - (margin * 2) - 2,
          height: 40,
          position: "fixed", 
          top: height - 160,
          left: margin - 1,
          borderColor: "white",
          borderStyle: "solid",
          borderWidth: 2,
          cursor: "crosshair",
          zIndex: 29
      }}
      ref={waveformRef}
      />
      <LoadingBar progress={loadingProgress}/>
      <div data-for="control" data-tip='repeat'>
        <RepeatBtn 
          onClick={handleRepeat} 
          className={classes.repeatBtn}
        />      
      </div>
      <div data-for="control" data-tip={isPlaying ? "pause" : "play"} className={classes.playContainer}>
        <Play 
          isPlaying={isPlaying} 
          progress={loadingProgress} 
          isLoadingBuffer={isLoadingBuffer}
          handlePlay={handlePlay}
        />
      </div>
      <div data-for="control"  data-tip='back'>
        <FastRewind  onClick={handleBack} className={classes.backBtn}/>
      </div>
      <div className={classes.volumeContainer}>
        <Volume waveSurfer={waveSurfer} width={"100%"}/>
      </div>
      <div className={classes.playbackContainer}>
        <Playback waveSurfer={waveSurfer} width={"100%"}/>
      </div>
      <div className={classes.starIDDiv}/>
      <div className={classes.starIDTextDiv}>
        {"KIC" + starID}
      </div>
      <ReactTooltip id="control"/>
    </>
  )
}

export default ControlPanel;