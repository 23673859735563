import {useState, useEffect} from 'react';

const useMobile = () => {
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {    // device detection
    if (/Mobi|Android/i.test(navigator.userAgent)) {
      setIsMobile(true)
    }
  }, [])

  return [isMobile]
};

export default useMobile;