import ReactTooltip from "react-tooltip";
import useMobile from "../hooks/useMobile";

const MobileReactTooltip = ({id}) => {
  const [isPlatformMobile] = useMobile()
  
  return (
    <ReactTooltip disable={isPlatformMobile} id={id} delayShow={750}/>
  )
}

export default MobileReactTooltip