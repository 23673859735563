import React from 'react';
import ReplayIcon from '@material-ui/icons/Replay';
import { useSearchParams } from 'react-router-dom';

function Reset({initFilterState}) {

  const [,setSearchParams] = useSearchParams()

  const handleReset = () => {
    const searchObj = Object.fromEntries(
      new URL(window.location.href).searchParams.entries()
    )
    setSearchParams({
      ...searchObj,
      ...initFilterState,
      reset: true
    })
  }

  return (
    <ReplayIcon
      style={{
        cursor: "pointer"
      }}
      onClick={handleReset}
    />
  );
}

export default Reset;