import React, { useEffect, useState} from "react"
import {makeStyles} from "@material-ui/core"
import MinBtn from './MinBtn'
import { useSearchParams } from "react-router-dom"
import InfoBtn from "@material-ui/icons/Info"
import ReactTooltip from './MobileReactTooltip'

const Info = ({starObjects}) => {
    const [info, setInfo] = useState([])
    const [showInfo, setShowInfo] = useState(false)
    const [searchParams] = useSearchParams()
    const [selectedStar, setSelectedStar] = useState(() => {
        if(searchParams.has('starID')) {
            return starObjects[+(searchParams.get('starID'))]
        }
        return starObjects[4049174]
    })

    const useStyles = makeStyles(() => ({
        container: {
            position: "absolute",
            bottom: "1px",
            left: "1px",
            width: "200px",
            height: "250px",
            backgroundColor: "black",
            opacity: "1.0",
            borderColor: "white",
            borderStyle: "solid",
            borderWidth: 1,
            overflow: "scroll",
            zIndex: "25"
        },
        span: {
            marginLeft: "16px",
            marginRight: "16px",
            fontFamily: `"Roboto", "Helvetica", "Arial", "sans-serif"`,
            overflowWrap: "break-word"
        },
        h3: {
            fontSize: "16px",
        },
        p: {
            fontSize: "12px",
            color: "white"
        },
        infoBtn: {
            // position: "absolute",
            // top: "20px",
            // right: "20px",
            "&:hover": {
                cursor: "pointer"
            },
            zIndex: "24"
        },
    }));
    const classes = useStyles();

    useEffect(() => {
        setSelectedStar(starObjects[+(searchParams.get('starID'))])
    }, [searchParams, starObjects])
    // TODO
    // Add lightkurve
    useEffect(() => {
        const wordMap = {
            "bp_rp": "Color",
            "abs_gmag": "Brightness",
            "r_est": "Distance (parsecs)",
            "temp": "Temp (K)",
            "mass": "Mass (Solar)",
            "radius": "Radius (Solar)"
        }
        if (selectedStar) {
            let newInfo = []
            Object.keys(selectedStar).forEach(key => {
                const name = key
                let value = selectedStar[key]
                value = [Math.floor(value * 1000) / 1000]
                const dontDisplay = ["", "kepid", "isSelected", "info"]
                let displayBool = true
                dontDisplay.forEach(str => {
                    if (str === name) {
                        displayBool = false
                    }
                })
                if (name === "temp" || name === "mass" || name === "radius") {
                    if (value <= 0.000001) {
                        displayBool = false
                    }
                }
                if (displayBool) {
                    newInfo = [...newInfo, 
                        ( <p key={name}> <b>{wordMap[name]}</b>  {" : " + value} </p>)
                    ]
                }
            })
            setInfo(newInfo)   
        }     
    }, [selectedStar])

    const handleInfo = () => {
        setShowInfo(prevState => !prevState)
    }
    const returnFormattedInfo = (info) => {
        const getFormattedText = (textArr) => {
            return (
                textArr.map((text, i) => {
                    return (
                        <p key={i} className={classes.p}> 
                            {text} 
                            <br/>
                        </p>
                    )
                })
            )
        }
        if (Array.isArray(info)) {
            return getFormattedText(info)
        } else {
            return (
                <> 
                    {getFormattedText(info.text)}
                    <hr/>
                    <p className={classes.p}><b>Relevant Links</b></p>
                    {info.links.map((linkData, i) => {
                        return (<a key={i} className={classes.p} href={linkData.link}>{linkData.label} </a>)
                    })}
                    <br/>
                    <br/>
                </>
            )  
        }
    }


    if (showInfo) {
        return (
            <div scrollY="true" className={classes.container}>
                <MinBtn handleInfo={handleInfo}/>
                <div className={classes.span}>
                    <h3 className={classes.h3}>
                        {'KIC' + selectedStar['kepid']}
                    </h3>
                    <hr/>
                    <div className={classes.p}>
                        {info}
                    </div>
                    {selectedStar['info'] ? <>
                        <hr/>
                        {returnFormattedInfo(selectedStar['info'])}
                    </> : <> </>}
                </div> 
            </div>
        )
    } else {
        return (
            <>
                <div data-for="info" data-tip="info">
                    <InfoBtn className={classes.infoBtn} onClick={handleInfo}/>
                </div>
                <ReactTooltip id="info"/>
            </>
        )
    }
}

export default Info