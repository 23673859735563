import {useEffect, useState} from 'react';
import {Slider, makeStyles} from '@material-ui/core'
import VolumeDown from '@material-ui/icons/VolumeDown';
import VolumeUp from '@material-ui/icons/VolumeUp';
import VolumeMute from '@material-ui/icons/VolumeMute';
import VolumeOff from '@material-ui/icons/VolumeOff';
import ReactTooltip from '../components/MobileReactTooltip'


const hi = 1
const lo = 0

const defaultVolume = 1.0

const Volume = ({width, waveSurfer}) => {

  const ampToVal = db => (db - lo) / (hi - lo) * 100
  const valToAmp = val => (val / 100) * (hi - lo) + lo
  
  const [isMuted, setIsMuted] = useState(false)
  const [volume, setVolume] = useState(defaultVolume)
  // const [volume, setVolume] = useState(() => {
  //   if (searchParams.has('volume')) {
  //     return searchParams.get('volume')
  //   } 
  //   return defaultVolume
  // })

  const volumeColor = isMuted ? "gray" : "white"

  const useStyles = makeStyles((theme) => ({
    container: {
      width: width,
      height: "60px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      marginBottom: "10px"
    },
    slider: {
      color: volumeColor,
      width: "100%"
    },
  }));

  useEffect(() => {
    if (waveSurfer) {
      waveSurfer.setMute(isMuted)
    }
  }, [isMuted, waveSurfer])


  useEffect(() => {
    if (waveSurfer && !isMuted) {
      waveSurfer.setVolume(volume)
    }
  }, [volume, waveSurfer, isMuted])

  // useEffect(() => {
  //   const searchObj = Object.fromEntries(new URL(window.location.href).searchParams.entries())
  //   setSearchParams({
  //     ...searchObj,
  //     'volume': volume
  //   })
  // }, [volume, setSearchParams])

  const handleVolumeChange = (e, newValue) => {
      const amp = valToAmp(newValue)
      setVolume(Math.round(amp * 100) / 100)
  }
  const iconSize = "25px"

  const classes = useStyles()
  const iconStyle = {
    fontSize: iconSize,
    color: volumeColor,
    cursor: "pointer"
  }

  const handleClick = () => setIsMuted((prev) => !prev)

  let VolumeIcon = () => <VolumeUp onClick={handleClick} style={iconStyle}/>

  if (volume === 0) {
    VolumeIcon = () => <VolumeMute onClick={handleClick}  style={iconStyle}/>
  } else if (volume < 0.5) {
    VolumeIcon = () => <VolumeDown onClick={handleClick} style={iconStyle}/>
  } 

  if (isMuted) {
    VolumeIcon = () => <VolumeOff onClick={handleClick}  style={iconStyle}/>
  }

  return (
      <div className={classes.container}>
        <div data-for='volume' data-tip={isMuted ? "unmute" : "mute"}>
          <VolumeIcon/>
        </div>
        <span style={{width: "5px"}}/>
        <Slider 
          value={ampToVal(volume)} 
          onChange={handleVolumeChange} 
          className={classes.slider} 
          aria-labelledby="volume"
          height={"50px"}
          data-tip={"volume"}
        />
        <ReactTooltip id="volume"/>
      </div>
  );
};


export default Volume;