import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from "@material-ui/core";
import RemoveOutlinedIcon from '@material-ui/icons/RemoveOutlined';
import ReactTooltip from "./MobileReactTooltip";

const filterLabelMap = {
    'blue giants': 'blueGiants',
    'curated': 'curated',
    'main sequence': 'mainSequence',
    'red giants': 'redGiants',
    'sub giants': 'subGiants',
    'white dwarfs': 'whiteDwarf'
}


const Search = ({starData, initFilterState, filterData, filterBounds}) => {
    const [isShowing, setIsShowing] = useState(false)
    const [searchID, setSearchID] = useState("")
    const [queriedStars, setQueriedStars] = useState(starData)
    const [thisStarData, setThisStarData] = useState(starData)
    const [searchParams, setSearchParams] = useSearchParams()
    const [filterState, setFilterState] = useState(() => {
        let thisInitState = {...initFilterState}
        filterData.forEach(key => {
            if (searchParams.has(key)) {
            thisInitState = {
                ...thisInitState,
                [key]: JSON.parse(searchParams.get(key))
            }
            }
        })
        return thisInitState
    })


    useEffect(() => {
        let newStarData = []
        filterData.forEach(key => {
            if (filterState[key]) {
                newStarData = [...newStarData, ...filterBounds[filterLabelMap[key]]['stars']]
            }
        })   
        setThisStarData(newStarData)
    }, [filterState, filterData, filterBounds])

    useEffect(() => {
        let newState = {...initFilterState}
        filterData.forEach(key => {
            if (searchParams.has(key)) {
                newState = {
                    ...newState,
                    [key]: JSON.parse(searchParams.get(key))
                }
            }
        })
        setFilterState(newState)
    }, [searchParams, filterData, initFilterState])

    const useStyles = makeStyles(() => ({
        container: {
            position: "absolute",
            bottom: "1px",
            left: "1px",
            width: "200px",
            height: "250px",
            backgroundColor: "black",
            opacity: "1.0",
            borderColor: "white",
            borderStyle: "solid",
            borderWidth: 1,
            zIndex: "25",
            overflow: "hidden"
        },
        minBtn: {
            position: "absolute",
            top: "15px",
            right: "10px",
            "&:hover": {
                cursor: "pointer"
            },
        },
        searchBtn: {
            // position: "absolute",
            // top: "20px",
            // right: "57px",
            "&:hover": {
                cursor: "pointer"
            },
            zIndex: "24"
        },
        textboxStyle: {
            width: "40%",
            marginLeft: "5%",
            borderColor: "white",
            fontFamily: `"Roboto", "Helvetica", "Arial", "sans-serif"`,
            fontSize: '12px',
            color: "white",
            background: "black",
        },
        header: {
            fontFamily: `"Roboto", "Helvetica", "Arial", "sans-serif"`,
            fontSize: '14px',
            color: "white", 
            marginLeft: "5%",
            fontWeight: "bold"
        },
        starDisplay: {
            display: "flex",
            flexDirection: "column",
            fontFamily: `"Roboto", "Helvetica", "Arial", "sans-serif"`,
            fontSize: '12px',
            color: "white",
            height: "80%",
            overflowY: "scroll",
        },  
        searchBar: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            background: "black",
            color: "white",
            width: "100%",
            height: "20%",
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: "white"
        }
    }));

    const classes = useStyles();

    useEffect(() => {

    })

    useEffect(() => {
        let newQueriedStars = []
        if (thisStarData) {
            thisStarData.forEach(star => {
                const curStarStr = "" + star.kepid
                if ((curStarStr.length) >= searchID.length) {
                    if (curStarStr.substring(0, (searchID.length)) === searchID) {
                        newQueriedStars = [...newQueriedStars, "" + star.kepid]
                    }
                }
            })
        }
        const searchObj = Object.fromEntries(new URL(window.location.href).searchParams.entries())
        newQueriedStars = newQueriedStars.map((star, i) => {
            const handleClick = () => {
                setSearchParams({
                  ...searchObj,
                  'starID': star
                })     
            }
            return (
                <div key={i} onClick={handleClick} style={{
                    borderColor: "white", 
                    borderStyle: "solid", 
                    borderWidth: "1px",
                    width: "100%",
                    height: "50px",
                    cursor: "pointer"
                }}> 
                    <p style={{marginLeft: "5%"}}>
                        {"KIC" + star} 
                    </p>
                </div>
            )
        })
        setQueriedStars(newQueriedStars)
    }, [searchID, setQueriedStars, thisStarData, setSearchParams])

    const handleIcon = () => setIsShowing(prevState =>  !prevState)
    const handleSearch = (e) => {
        const value = e.target.value
        const validValues = "0123456789"
        let lastVal = value.substring(value.length - 1, value.length)
        if (value === "" || validValues.includes(lastVal)) {
            setSearchID(value)
        }
    }

    if (!isShowing) {
        return (
            <>
                <div data-for="search" data-tip="search">
                    <SearchIcon onClick={handleIcon} className={classes.searchBtn}/>
                </div>
                <ReactTooltip id="search"/>
            </>
        )
    } else {
        return (
            <div className={classes.container}>
                <RemoveOutlinedIcon className={classes.minBtn} onClick={handleIcon}/>
                <div className={classes.searchBar}>
                    <span className={classes.header}>STAR ID</span>
                    <input 
                        className={classes.textboxStyle} 
                        type="text" 
                        value={searchID} 
                        onChange={handleSearch} 
                    />
                </div>
                <div className={classes.starDisplay}>
                    {queriedStars}
                </div>
            </div>
        ) 
    }


}

export default Search