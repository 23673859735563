import {useEffect, useState} from 'react';
import {Slider, makeStyles} from '@material-ui/core'
import ReplayIcon from '@material-ui/icons/Replay';
import { DirectionsRun, DirectionsWalk } from '@material-ui/icons';
import ReactTooltip from './MobileReactTooltip';
// import { useSearchParams } from 'react-router-dom';


const defaultPlayback = 1.0

const Playback = ({width, waveSurfer}) => {


  const playbackToVal = playback => ((Math.log2(playback) + 1) / 2) * 100
  const valToPlayback = val => Math.pow(2, (val / 100) * 2 - 1)
  // const [searchParams, setSearchParams] = useSearchParams()
  const [playbackState, setPlaybackState] = useState(defaultPlayback)


  const useStyles = makeStyles((theme) => ({
    slider: {
      color: "white",
      width: "100%"
    },
    sliderDiv: {
      width: width,
      height: "60px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      marginBottom: "10px"
    },  
    text: {
      position: "absolute",
      width: "100%",
      height: "20px",
      top: 10
    },
    reset: {
      "&:hover": {
        cursor: "pointer"
      }
    },
  }));

  useEffect(() => {
    console.log(waveSurfer)
    console.log(playbackState)
    if (waveSurfer) {
      waveSurfer.setPlaybackRate(playbackState)
    }
  }, [playbackState, waveSurfer])

  const handlePlaybackChange = (e, newValue) => {
      setPlaybackState(Math.round(valToPlayback(newValue) * 100) / 100)
  }

  const handleReset = () => setPlaybackState(defaultPlayback)

  const classes = useStyles();

  let SpeedIcon = () => <DirectionsRun/>
  if (playbackState < 1.0) {
    SpeedIcon = () => <DirectionsWalk/>
  }

  
  return (
    <div className={classes.sliderDiv}>
      {/* <Typography className={classes.text} align="center"> 
        Playback Rate
      </Typography> */}
      <SpeedIcon/>
      <span style={{width: "5px"}}/>
        <Slider 
          value={playbackToVal(playbackState)} 
          onChange={handlePlaybackChange} 
          className={classes.slider} 
          aria-labelledby="continuous-slider"
          data-for="playback" 
          data-tip="playback rate"
        />
      <span style={{width: "5px"}}/>
      <div data-for="playback" data-tip="reset playback rate">
        <ReplayIcon className={classes.reset} onClick={handleReset} />
      </div>
      <ReactTooltip id="playback"/>
    </div>
  )
};


export default Playback;