import React, { useEffect, useState } from 'react';
import Switch from '@material-ui/core/Switch';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import {withStyles} from '@material-ui/core';
import {useSearchParams} from "react-router-dom"

export default function RadioSelection(props) {

  const {initFilterState, filterBounds, filterData} = props
  const [searchParams, setSearchParams] = useSearchParams()
  const [state, setState] = useState(() => {
    let thisInitState = {...initFilterState}
    console.log(thisInitState)
    filterData.forEach(key => {
      if (searchParams.has(key)) {
        thisInitState = {
          ...thisInitState,
          [key]: JSON.parse(searchParams.get(key))
        }
      }
    })
    return thisInitState
  })

  useEffect(() => {
    const searchObj = Object.fromEntries(
      new URL(window.location.href).searchParams.entries()
    )
    setSearchParams({
      ...searchObj,
      ...state
    })
  }, [state, setSearchParams])

  const WhiteSwitch = withStyles({
    switchBase: {
      color: "white",
      '&$checked': {
        color: "white",
      },
      '&$checked + $track': {
        backgroundColor: "white",
      },
      '&$track': {
        backgroundColor: "white",
      },
    },
    checked: {},
    track: {},
  })(Switch);

  const handleChange = (event) => {
    setState((prevState) => { 
      return { 
        ...prevState, 
        [event.target.name]: event.target.checked
      }}
    );
  };

  const makeLabels = () => {
    return (
      Object.keys(filterBounds).map(key => {
        let thisFilterBound = filterBounds[key]
        return (
          <div 
            key={thisFilterBound.label}
            style={{
              display: "flex", 
              flexDirection: "row", 
              alignItems: "center", 
              justifyContent: "center",
              width: "100%",
              height: "30px"
            }}
          >
            <div style={{width: '75%'}}>
              <p style={{
                marginLeft: "10px",             
                fontFamily: `"Roboto", "Helvetica", "Arial", "sans-serif"`,
                fontSize: '14px',
                color: "white",
              }}> {thisFilterBound.label} </p>
            </div>
            <div style={{width: '25%'}}>
              <FormControlLabel
                value={thisFilterBound.label}
                control={<WhiteSwitch 
                  size="small"
                  checked={state[thisFilterBound.label]} 
                  onChange={handleChange} 
                  name={thisFilterBound.label}
                />}
              />
            </div>
          </div>
        )
      })
    )
  }

  return (
    <FormControl component="fieldset">
      <RadioGroup row aria-label="starFilter" name="starFilter">
        {makeLabels()}
      </RadioGroup>
    </FormControl>
  );
}